import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import _ from 'lodash'

import history from '../../../history'
import Title from '../../Title'
import Button from '../../Button'

const NewUploadPaymentIntentError = ({ location }) => {
  const { upload, errCode } = _.get(location, 'state', {})

  useEffect(() => {
    if (!upload) {
      history.push('/dashboard')
    }
  }, [upload])

  return (
    <>
      <Title title="Upload created without add-ons" />
      <div className="max-w-5xl mx-auto px-4 py-1">
        <h3 className="text-xl font-semibold tracking-tighter mt-4">
          Your upload number is {upload.uploadNumber}
        </h3>
        <div className="text-sm text-gray-600 mt-2 mb-6 space-y-2">
          <p>
            We were unable to process payment for your selected add-ons, however
            we have still created your upload so you don't lose any work.
          </p>
          <p>
            Please raise a support ticket detailing your upload number, add-ons
            required and error code {errCode} so our team can investigate this
            issue.
          </p>
          <p>
            If this upload was created using IXI Flasher, payment of the IXI
            Flasher surcharge will need to be made manually before we begin work
            on this file. Please contact our team.
          </p>
        </div>
        <div className="flex items-center space-x-3">
          <Button
            as={Link}
            color="primary"
            to={{
              pathname: `/uploads/view/${upload.id}`,
              state: { upload },
            }}
          >
            View upload
          </Button>
          <Button as={Link} to="/support/new">
            Create a ticket
          </Button>
        </div>
      </div>
    </>
  )
}

export default NewUploadPaymentIntentError
