import React, { useEffect, useState } from 'react'

import usePrivateSocket from '../../../hooks/usePrivateSocket'
import { RE_CREDITED, ON_HOLD } from '../../../utils/uploads'
import Button from '../../Button'
import Textarea from '../../Textarea'
import ViewUploadRecreditModal from './ModalRecredit'
import ViewUploadRecreditWithPriorityModal from './ModalRecreditWithPriority'
import ViewUploadRefundIXIFlasherModal from './ModalRefundIXIFlasher'
import ViewUploadOnHoldModal from './ModalOnHold'
import ViewUploadChangeStage3CreditCostModal from './ModalChangeStage3CreditCost'
import {
  FETCH_UPLOAD_ADMIN_NOTES,
  EDIT_UPLOAD_ADMIN_NOTES,
  UPLOAD_ADMIN_NOTES_EDITED,
} from '../../../utils/sockets'

const ViewUploadAdmin = ({ upload, setUpload, socket, isDisconnected }) => {
  const [recreditModalOpen, setRecreditModalOpen] = useState(false)
  const [recreditWithPriorityModalOpen, setRecreditWithPriorityModalOpen] =
    useState(false)
  const [refundIXIFlasherModalOpen, setRefundIXIFlasherModalOpen] =
    useState(false)
  const [onHoldModalOpen, setOnHoldModalOpen] = useState(false)
  const [changeStage3CreditCostModalOpen, setChangeStage3CreditCostModalOpen] =
    useState(false)
  const [adminNotes, setAdminNotes] = useState('')
  const [hasFetchedAdmin, setHasFetchedAdmin] = useState(false)
  const [connectionErr, setConnectionErr] = useState(false)
  const [hasEdits, setHasEdits] = useState(false)
  const [hasSaved, setHasSaved] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const [{ inRoom, err }, { socket: adminSocket }] = usePrivateSocket(
    '/upload-admin',
    upload.id
  )

  useEffect(() => {
    if (inRoom && !hasFetchedAdmin) {
      setHasFetchedAdmin(true)

      adminSocket.emit(FETCH_UPLOAD_ADMIN_NOTES, (err, adminNotes) => {
        if (!err) {
          // Empty string returns true boolean
          setAdminNotes(adminNotes === true ? '' : adminNotes)
        } else {
          setConnectionErr(true)
        }
      })
    }
  }, [inRoom, adminSocket, hasFetchedAdmin])

  useEffect(() => {
    if (adminSocket) {
      adminSocket.on(UPLOAD_ADMIN_NOTES_EDITED, (newAdminNotes) => {
        setAdminNotes(newAdminNotes)
      })

      return () => adminSocket.off(UPLOAD_ADMIN_NOTES_EDITED)
    }
  }, [adminSocket])

  const ixiFlasherAddon = upload.addons.find(
    (addon) => addon.type === 'IXI_FLASHER_SURCHARGE'
  )

  const handleAdminNotesChange = (e) => {
    setHasEdits(true)
    setHasSaved(false)
    setAdminNotes(e.target.value)
  }

  const handleAdminNotesSave = () => {
    setIsSaving(true)

    adminSocket.emit(EDIT_UPLOAD_ADMIN_NOTES, adminNotes, (err, success) => {
      if (success) {
        setHasSaved(true)
        setHasEdits(false)
        setIsSaving(false)
      } else {
        setConnectionErr(true)
      }
    })
  }

  return (
    <div className="divide-y divide-gray-200">
      <div className="px-6 pt-6 pb-6 space-y-3 sm:space-y-0 sm:flex sm:space-x-6 sm:items-center sm:justify-between">
        <div>
          <h2 className="text-lg font-semibold tracking-tight mb-0.5">
            Re-credit upload
          </h2>
          <div className="text-gray-600 text-sm space-y-2 max-w-prose">
            <p>Refund the dealer and cancel this upload.</p>
          </div>
        </div>
        <div>
          <Button
            disabled={upload.status === RE_CREDITED || isDisconnected}
            color="red-invert"
            onClick={() => setRecreditModalOpen(true)}
          >
            Re-credit upload
          </Button>
        </div>
      </div>
      <div className="px-6 pt-6 pb-6 space-y-3 sm:space-y-0 sm:flex sm:space-x-6 sm:items-center sm:justify-between">
        <div>
          <h2 className="text-lg font-semibold tracking-tight mb-0.5">
            Re-credit upload and priority add-on
          </h2>
          <div className="text-gray-600 text-sm space-y-2 max-w-prose">
            <p>
              Refund the dealer for the upload and the priority add-on and
              cancel this upload.
            </p>
          </div>
        </div>
        <div>
          <Button
            disabled={
              upload.status === RE_CREDITED ||
              !upload.priority ||
              isDisconnected
            }
            color="red-invert"
            onClick={() => setRecreditWithPriorityModalOpen(true)}
          >
            Re-credit upload & priority
          </Button>
        </div>
      </div>
      {ixiFlasherAddon?.purchased && (
        <div className="px-6 pt-6 pb-6 space-y-3 sm:space-y-0 sm:flex sm:space-x-6 sm:items-center sm:justify-between">
          <div>
            <h2 className="text-lg font-semibold tracking-tight mb-0.5">
              Refund IXI Flasher surcharge
            </h2>
            <div className="text-gray-600 text-sm space-y-2 max-w-prose">
              <p>Does not affect the actual upload status or credits.</p>
            </div>
          </div>
          <div>
            <Button
              disabled={ixiFlasherAddon.refunded || isDisconnected}
              color="red-invert"
              onClick={() => setRefundIXIFlasherModalOpen(true)}
            >
              Refund surcharge
            </Button>
          </div>
        </div>
      )}
      <div className="px-6 pt-6 pb-6 space-y-3 sm:space-y-0 sm:flex sm:space-x-6 sm:items-center sm:justify-between">
        <div>
          <h2 className="text-lg font-semibold tracking-tight mb-0.5">
            Place on hold
          </h2>
          <div className="text-gray-600 text-sm space-y-2 max-w-prose">
            <p>
              Changes the status to "On hold" and removes upload from turnaround
              calculation.
            </p>
          </div>
        </div>
        <div>
          <Button
            disabled={
              [ON_HOLD, RE_CREDITED].includes(upload.status) || isDisconnected
            }
            onClick={() => setOnHoldModalOpen(true)}
          >
            Place on hold
          </Button>
        </div>
      </div>
      {upload.tune.tuneRequired === 'STAGE3' && (
        <div className="px-6 pt-6 pb-6 space-y-3 sm:space-y-0 sm:flex sm:space-x-6 sm:items-center sm:justify-between">
          <div>
            <h2 className="text-lg font-semibold tracking-tight mb-0.5">
              Change stage 3 credit cost
            </h2>
            <div className="text-gray-600 text-sm space-y-2 max-w-prose">
              <p>
                Changing the cost of this upload for the first time will also
                change the upload status to in-progress.
              </p>
              <p>
                Note that its possible to change the cost multiple times and the
                system will just add or deduct the credit difference from the
                dealers credit balance.
              </p>
              <p>
                If the dealer does not have enough credits in their account you
                will need to advise them to purchase a credit package.
              </p>
              <div className="flex items-start space-x-3">
                <div className="bg-gray-100 rounded-md px-4 py-4">
                  <span className="text-gray-900 font-medium block">
                    Current credit cost:
                  </span>
                  <span className="text-lg block pt-1 text-gray-900 font-semibold">
                    {upload.stage3CreditCost
                      ? `${upload.stage3CreditCost} credit(s)`
                      : 'Pending'}
                  </span>
                </div>
                <div className="bg-gray-100 rounded-md px-4 py-4">
                  <span className="text-gray-900 font-medium block">
                    Current dealer balance:
                  </span>
                  <span className="text-lg block pt-1 text-gray-900 font-semibold">
                    {upload.createdBy.dealer?.credit?.balance} credit(s)
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Button
              disabled={upload.status === RE_CREDITED || isDisconnected}
              onClick={() => setChangeStage3CreditCostModalOpen(true)}
            >
              Change credit cost
            </Button>
          </div>
        </div>
      )}
      <div className="px-6 pt-6 pb-6">
        <div className="space-y-3 sm:space-y-0 sm:flex sm:space-x-6 sm:items-center sm:justify-between">
          <div>
            <h2 className="text-lg font-semibold tracking-tight mb-0.5">
              Admin notes
            </h2>
            <div className="text-gray-600 text-sm space-y-2 max-w-prose">
              <p>Internal notes that cannot be seen by the dealer.</p>
            </div>
          </div>
          <div>
            <Button
              disabled={isSaving || !hasEdits || isDisconnected}
              onClick={handleAdminNotesSave}
            >
              {hasSaved && 'Changes saved'}
              {isSaving && !hasSaved && 'Saving...'}
              {!hasSaved && !isSaving && 'Save changes'}
            </Button>
          </div>
        </div>
        <div className="mt-4">
          <Textarea
            label="Internal notes"
            disabled={connectionErr || err || !hasFetchedAdmin}
            onChange={handleAdminNotesChange}
            value={adminNotes}
            minRows={5}
          />
        </div>
      </div>
      <ViewUploadRecreditModal
        isOpen={recreditModalOpen}
        setIsOpen={setRecreditModalOpen}
        socket={socket}
        setUpload={setUpload}
      />
      <ViewUploadRecreditWithPriorityModal
        isOpen={recreditWithPriorityModalOpen}
        setIsOpen={setRecreditWithPriorityModalOpen}
        socket={socket}
        setUpload={setUpload}
      />
      <ViewUploadRefundIXIFlasherModal
        isOpen={refundIXIFlasherModalOpen}
        setIsOpen={setRefundIXIFlasherModalOpen}
        socket={socket}
        setUpload={setUpload}
      />
      <ViewUploadOnHoldModal
        isOpen={onHoldModalOpen}
        setIsOpen={setOnHoldModalOpen}
        socket={socket}
        setUpload={setUpload}
      />
      <ViewUploadChangeStage3CreditCostModal
        isOpen={changeStage3CreditCostModalOpen}
        setIsOpen={setChangeStage3CreditCostModalOpen}
        socket={socket}
        upload={upload}
        setUpload={setUpload}
      />
    </div>
  )
}

export default ViewUploadAdmin
