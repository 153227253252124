import { objectToOptions } from './objects'

const ACTIVE = 'ACTIVE'
const ON_HOLD = 'ON_HOLD'
const PENDING = 'PENDING'
const ARCHIVED = 'ARCHIVED'

const MASTER = 'MASTER'
const SLAVE = 'SLAVE'

const statusLookup = {
  ACTIVE: 'Active',
  PENDING: 'Pending',
  ON_HOLD: 'On hold',
  ARCHIVED: 'Archived',
}

const statusLabelColorLookup = {
  ACTIVE: 'green',
  ON_HOLD: 'red',
  PENDING: 'orange',
  ARCHIVED: 'gray',
}

const KESS_V2 = 'Kess V2'

const toolLookup = {
  KESS_V2,
  KESS3: 'Kess 3',
  KTAG: 'KTAG',
  CMD: 'CMD',
  NEW_GENIUS: 'New Genius',
  TRASDATA: 'Trasdata',
  AUTOTUNER: 'Autotuner',
  IXI_FLASHER: 'IXI Flasher',
}

export {
  statusLookup,
  toolLookup,
  ACTIVE,
  ON_HOLD,
  PENDING,
  ARCHIVED,
  MASTER,
  SLAVE,
  KESS_V2,
}

export const getStatus = (key) => statusLookup[key]
export const getStatusLabelColor = (key) => statusLabelColorLookup[key]
export const getStatusOptions = () => objectToOptions(statusLookup)
export const getTool = (key) => toolLookup[key]
