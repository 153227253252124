import React, { useState } from 'react'

import { REFUND_UPLOAD_ADDONS } from '../../../utils/sockets'
import Button from '../../Button'
import Dialog from '../../Dialog'

const ViewUploadRefundIXIFlasherModal = (props) => {
  const { isOpen, setIsOpen, socket, setUpload } = props

  const [isLoading, setIsLoading] = useState(false)
  const [success, setSuccess] = useState(null)
  const [error, setError] = useState(null)

  const handleSubmit = () => {
    setIsLoading(true)

    socket.emit(
      REFUND_UPLOAD_ADDONS,
      { addons: ['IXI_FLASHER_SURCHARGE'] },
      (err, addons) => {
        setIsLoading(false)

        if (addons) {
          setSuccess(true)
          setUpload({ addons })
        }

        if (err) {
          setError(err)
        }
      }
    )
  }

  if (error) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="An error occurred"
        buttonProps={null}
      >
        <p className="mt-2 text-gray-600 text-sm">
          Could not refund IXI Flasher surcharge. Please try again later.
        </p>
        <Button className="mt-4" onClick={() => setIsOpen(false)}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  if (success) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => setIsOpen(false)}
        title="Successfully refunded"
        buttonProps={null}
      >
        <p className="mt-2 text-gray-600 text-sm">
          The IXI Flasher surcharge has been successfully refunded.
        </p>
        <Button className="mt-4" onClick={() => setIsOpen(false)}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={() => setIsOpen(false)}
      title="Refund IXI Flasher surcharge"
      buttonProps={{
        disabled: isLoading,
        onClick: handleSubmit,
        children: isLoading ? 'Refunding...' : 'Refund',
        color: 'red',
      }}
    >
      <p className="mt-2 text-gray-600 text-sm">
        <strong>
          Are you sure you wish to refund the IXI Flasher surcharge?
        </strong>
      </p>
      <p className="mt-2 text-gray-600 text-sm">
        Refunding the surcharge will not re-credit the upload or change the
        upload status.
      </p>
      <p className="mt-2 text-gray-600 text-sm">
        Please note that a refund can take between 5-10 days depending on the
        dealer's bank.
      </p>
      <p className="mt-2 text-sm text-red-600">This action is irreversible.</p>
    </Dialog>
  )
}

export default ViewUploadRefundIXIFlasherModal
